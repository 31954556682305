import React from 'react';
import {
  FaDiscord,
  FaInstagramSquare,
  FaTiktok,
  FaTwitter,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const betaBadge =
  'inline-flex items-center rounded-md bg-indigo-600 px-1 text-indigo-100 text-xs font-extralight';

export default function Footer() {
  return (
    <footer id="footer" className="w-full bg-slate-100">
      <hr></hr>
      <div className="container mx-auto py-1 flex-col flex-wrap">
        <div className="mx-auto">
          <div className="container mx-auto grid grid-cols-3 md:grid-cols-6 gap-1 max-w-5xl px-2 py-2">
            <Link
              to="/about"
              className="bg-gray-800 text-white rounded-md p-2 text-sm sm:hover:bg-indigo-900 text-center xs:text-xs"
            >
              About
            </Link>
            <Link
              to="/blog"
              className="bg-gray-800 text-white rounded-md p-2 text-sm sm:hover:bg-indigo-900 text-center xs:text-xs"
            >
              Blog
            </Link>
            <Link
              to="/faqs"
              className="bg-gray-800 text-white rounded-md p-2 text-sm sm:hover:bg-indigo-900 text-center xs:text-xs"
            >
              FAQs
            </Link>
            <Link
              to="/membership"
              className="bg-gray-800 text-white rounded-md p-2 text-sm sm:hover:bg-indigo-900 text-center xs:text-xs"
            >
              Membership
            </Link>
            <Link
              to="/ambassador"
              className="bg-gray-800 text-white rounded-md p-2 text-sm sm:hover:bg-indigo-900 text-center xs:text-xs"
            >
              Ambassadors
            </Link>
            <Link
              to="/contact"
              className="bg-gray-800 text-white rounded-md p-2 text-sm sm:hover:bg-indigo-900 text-center xs:text-xs"
            >
              Contact
            </Link>
          </div>

          <div className="flex justify-center flex-nowrap space-x-4 py-2 px-2 xs:px-6">
            <Link to="/terms" className="text-sm hover:underline text-center">
              Terms & Conditions
            </Link>

            <Link to="/privacy" className="text-sm hover:underline text-center">
              Privacy Policy
            </Link>

            <Link to="/contact" className="text-sm hover:underline text-center">
              Send Feedback
            </Link>
          </div>

          <div className="flex justify-center flex-nowrap space-x-4 py-2 px-2 xs:px-6">
            <Link
              to="/golfcoursesitemap"
              className="text-sm hover:underline text-center"
            >
              Golf Courses A to Z
            </Link>
            <Link
              to="/golfcountysitemap"
              className="text-sm hover:underline text-center"
            >
              County Rankings A to Z
            </Link>
            <Link
              to="/golfcollectionssitemap"
              className="text-sm hover:underline text-center"
            >
              GolfLists Collections A to Z
            </Link>
          </div>

          <div className="flex justify-center space-x-6 py-2">
            <a
              className="px-4"
              target="_blank"
              rel="noreferrer"
              href="https://discord.com/invite/2kvu7rkgbs"
              aria-label="Navigate to Golf List's Discord forum"
            >
              <FaDiscord name="discord" />
            </a>
            <a
              className="px-4"
              target="_blank"
              rel="noreferrer"
              href="https://www.tiktok.com/@officialgolflists?lang=en"
              aria-label="Navigate to Golf List's Tiktok"
            >
              <FaTiktok name="tiktok" />
            </a>
            <a
              className="px-4 text-lg"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/michaeljohngolf/"
              aria-label="Navigate to Golf List's Instagram"
            >
              <FaInstagramSquare name="instagram" />
            </a>
            <a
              className="px-4 text-lg"
              target="_blank"
              rel="noreferrer"
              aria-label="Navigate to Golf List's Twitter"
              href="https://twitter.com/GolfLists/"
            >
              <FaTwitter name="twitter" />
            </a>
          </div>
        </div>
        <div>
          <p className="text-center text-xs text-gray-600 px-1 pb-1">
            &copy; 2023 GolfLists.com all rights reserved by Golf Lists Limited.
          </p>
          <p className="text-center text-xs text-gray-600 px-2 pb-1">
            We are in <span className={betaBadge}>Beta</span> so you might find
            something on the site that doesn't work. If you do please{' '}
            <a
              href="https://discord.com/invite/2kvu7rkgbs"
              className="text-indigo-700 underline hover:cursor-pointer"
              target="_blank"
              rel="noopener noreferrer"
            >
              let us know and we will fix it!
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
