export const postsList = [
  {
    id: 1,
    title: "The origin story of Golf Lists",
    blogurl: "the-origin-story-of-golf-lists",
    keywords: "origins, golflists.com, founder",
    description: "The origin story of Golf Lists!",
    imageUrl:
      "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/standrews.webp?t=2023-08-29T15%3A50%3A23.077Z",
    date: "Jul 11, 2023",
    datetime: "2023-11-07",
    relatedArticles: [2, 3],
    author: {
      name: "Michael Tillcock",
      imageUrl:
        "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/blogHeadshot-removebg-preview.webp?t=2023-08-30T15%3A52%3A12.853Z",
    },
  },
  {
    id: 2,
    title: "What is GolfLists.com?",
    blogurl: "what-is-golflists-com",
    keywords: "origins, golflists.com, founder",
    description: "What is GolfLists.com?",
    imageUrl:
      "https://api.golflists.com/storage/v1/object/public/brand/GL_LOGO_300_302.png?t=2024-03-01T09%3A08%3A56.424Z",
    date: "Jul 11, 2023",
    datetime: "2023-11-07",
    relatedArticles: [1, 3],
    author: {
      name: "Michael Tillcock",
      imageUrl:
        "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/blogHeadshot-removebg-preview.webp?t=2023-08-30T15%3A52%3A12.853Z",
    },
  },
  {
    id: 3,
    title: "How to rank a golf course",
    blogurl: "how-to-rank-a-golf-course",
    keywords:
      "golflists, golf course rankings, methodology, panel, England, Scotland, Wales, Ireland, Northern Ireland",
    description: "Golf Lists proposed methodology for ranking golf courses.",
    imageUrl:
      "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/Golfplatz_Wittenbeck_Germany.webp?t=2023-08-29T15%3A49%3A45.110Z",
    date: "July 20, 2023",
    datetime: "2023-20-07",
    relatedArticles: [1, 3],
    author: {
      name: "Michael Tillcock",
      imageUrl:
        "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/blogHeadshot-removebg-preview.webp?t=2023-08-30T15%3A52%3A12.853Z",
    },
  },
  {
    id: 4,
    title: "How to upload your handicap certificate",
    blogurl: "how-to-upload-your-handicap-certificate",
    keywords:
      "upload handicap certificate, how to find handicap certificate, myEG handicap certificate",
    description:
      "This blog describes how to find and upload your handicap certificate to the golf lists websites.",
    imageUrl:
      "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/RecordPlayed.webp?t=2023-08-29T15%3A50%3A02.920Z",
    date: "Jul 25, 2023",
    datetime: "2023-23-07",
    relatedArticles: [1, 2],
    author: {
      name: "Michael Tillcock",
      imageUrl:
        "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/blogHeadshot-removebg-preview.webp?t=2023-08-30T15%3A52%3A12.853Z",
    },
  },
  {
    id: 5,
    title: "The GolfLists Score Explained",
    blogurl: "the-golflists-score-explained",
    keywords: "golflists score, methodology",
    description:
      "An overview of what the GolfLists Score is and how GolfLists uses it.",
    imageUrl:
      "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/confidentialguide.jpg?t=2023-10-16T14%3A10%3A54.015Z",
    date: "Oct 16, 2023",
    datetime: "2023-16-10",
    relatedArticles: [3, 6],
    author: {
      name: "Michael Tillcock",
      imageUrl:
        "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/blogHeadshot-removebg-preview.webp?t=2023-08-30T15%3A52%3A12.853Z",
    },
  },
  {
    id: 6,
    title: "Mackenzie's Ideal Golf Course Design Principles",
    blogurl: "mackenzies-ideal-golf-course-design-principles",
    keywords:
      "Alister Mackenzie, the good doctor, Mackenzie, strategic design, golf architecture",
    description:
      "Alister Mackenzie's design principles and how Golf Lists uses them.",
    imageUrl:
      "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/Dr._Alister_MacKenzie.jpg?t=2023-10-16T14%3A57%3A00.284Z",
    date: "Oct 16, 2023",
    datetime: "2023-16-10",
    relatedArticles: [5, 7],
    author: {
      name: "Michael Tillcock",
      imageUrl:
        "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/blogHeadshot-removebg-preview.webp?t=2023-08-30T15%3A52%3A12.853Z",
    },
  },
  {
    id: 7,
    title:
      "Fairways and Factions: Bridging the Divide in Strong Opinions About The Old Course",
    blogurl: "fairways-factions-bridging-divide-old-course-rankings",
    keywords:
      "old course opinions, old course reviews, st andrews, old course design, old course blog",
    description:
      "A discussion on the contrasting opinions on the Old Course at St Andrews, and how to avoid falling into the trap of 'invincible ignorance'.",
    imageUrl:
      "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/standrewscartoon.png?t=2023-11-20T11%3A01%3A14.429Z",
    date: "Nov 20, 2023",
    datetime: "2023-20-11",
    relatedArticles: [6, 8],
    author: {
      name: "Michael Tillcock",
      imageUrl:
        "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/blogHeadshot-removebg-preview.webp?t=2023-08-30T15%3A52%3A12.853Z",
    },
  },
  {
    id: 8,
    title:
      "Golf by Numbers: Embracing 'Strokes Gained' in Golf Course Architecture",
    blogurl: "golf-by-numbers-probability-course-design",
    keywords:
      "decade, strokes gained, every shot counts, mark broadie, golf architecture, strategic design, walton heath, golf statistics, scott fawcett",
    description:
      "An exploration into how strokes gained based course management strategies like Decade are challenging strategic design principles in golf course architecture.",
    imageUrl:
      "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/course_strat.png?t=2023-11-20T15%3A37%3A00.222Z",
    date: "Nov 21, 2023",
    datetime: "2023-21-11",
    relatedArticles: [6, 5],
    author: {
      name: "Michael Tillcock",
      imageUrl:
        "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/blogHeadshot-removebg-preview.webp?t=2023-08-30T15%3A52%3A12.853Z",
    },
  },
  {
    id: 9,
    title: "How golf architecture can be good",
    blogurl: "how-golf-architecture-can-be-good",
    keywords: "golf, architecture, can art be good,",
    description:
      "A short blog considering whether golf courses can be objectively good, or if it is all down to personal opinion.",
    imageUrl:
      "https://api.golflists.com/storage/v1/object/public/blog_images/blog_tiles/mona_lisa.jpg?t=2024-03-01T09%3A47%3A07.805Z",
    date: "Dec 21, 2023",
    datetime: "2023-21-11",
    relatedArticles: [6, 5],
    author: {
      name: "Michael Tillcock",
      imageUrl:
        "https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/blog_images/blog_tiles/blogHeadshot-removebg-preview.webp?t=2023-08-30T15%3A52%3A12.853Z",
    },
  },
  // More posts...resize blog images to 385 by 425 px before saving to supabase storage
];
