import {
  faComments,
  faHouseUser,
  faSignOut,
  faTimes,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

//import { setTab } from "../../features/currentTab/currentTabSlice";
import { supabase } from '../../utils/supabase';
import ListsTabMenuMobile from './ListsTabMenuMobile';

function NavBarModal({ isOpen, handleToggle, user }) {
  //const currentMenu = useSelector((state) => state.currentMenu.value);
  const navigate = useNavigate();

  const handleMenuClick = (menuName) => {
    handleToggle(); // Close modal after menu selection
  };

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/'); // Navigate to the homepage
      handleToggle(); // Close modal after signing out
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-100 bg-gray-800 bg-opacity-75 flex justify-center items-center">
      <div className="bg-indigo-200 w-full  max-w-md h-full overflow-auto">
        <div className="flex justify-end p-4">
          <button
            onClick={handleToggle}
            className="text-gray-500 hover:text-gray-800"
          >
            <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
          </button>
        </div>

        <div className=" flex flex-col w-full items-center justify-center space-y-4 p-4">
          {/* Main Nav Links */}
          <div className="bg-white border border-gray-300 w-full">
            <Link
              to="/"
              onClick={() => handleMenuClick('Home')}
              className="flex items-center space-x-2 p-2 rounded-md hover:bg-gray-100"
            >
              <FontAwesomeIcon icon={faHouseUser} className="text-indigo-500" />
              <span className="text-indigo-700 font-medium">Home</span>
            </Link>
            <Link
              to="/connect"
              onClick={() => handleMenuClick('Connect')}
              className="flex items-center space-x-2 p-2 rounded-md hover:bg-gray-100"
            >
              <FontAwesomeIcon icon={faComments} className="text-indigo-500" />
              <span className="text-indigo-700 font-medium">Connect</span>
            </Link>
            <Link
              to="/profile"
              onClick={() => handleMenuClick('Profile')}
              className="flex items-center space-x-2 p-2 rounded-md hover:bg-gray-100"
            >
              <FontAwesomeIcon icon={faUserGroup} className="text-indigo-500" />
              <span className="text-indigo-700 font-medium">Profile</span>
            </Link>
            {user && (
              <button
                onClick={handleSignOut}
                className="flex items-center space-x-2 p-2 rounded-md hover:bg-gray-100"
              >
                <FontAwesomeIcon icon={faSignOut} className="text-indigo-500" />
                <span className="text-indigo-700 font-medium">Sign Out</span>
              </button>
            )}
            {/* Lists Tab Menu Mobile */}
          </div>
          <ListsTabMenuMobile handleToggle={handleToggle} />
        </div>
      </div>
    </div>
  );
}

export default NavBarModal;
