import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { supabase } from '../../utils/supabase';

const customStyles = {
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 150, // or use a higher value if needed
  }),
};

function GolfCourseSelect({
  onChange,
  placeholder = 'Select',
  selectedValue,
  onKeyDown,
  zIndexOverride,
}) {
  const [golfCourses, setGolfCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGolfCourses = async () => {
      try {
        const { data } = await supabase
          .from('golf_courses')
          .select('course_name, course_country,course_uid')
          .order('course_name', { ascending: true });
        //console.log(`Test1 ${data}`);

        const formattedCourses = data.map((course) => ({
          label: course.course_name,
          value: course.course_name,
          country: course.course_country,
          courseuid: course.course_uid,
        }));
        //console.log(`Test2 ${formattedCourses}`);
        setGolfCourses(formattedCourses);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching golf courses:', error);
      }
    };

    fetchGolfCourses();
  }, []);
  return (
    <>
      <Select
        options={golfCourses}
        isLoading={isLoading}
        placeholder={isLoading ? 'Loading...' : placeholder}
        onChange={onChange}
        styles={customStyles}
        value={selectedValue}
        onKeyDown={onKeyDown} // <-- Pass the onKeyDown prop here
      />
    </>
  );
}

export default GolfCourseSelect;
