import React from 'react';
import { useNavigate } from 'react-router-dom';

import About from '../about/About';
import BlogMenu from '../blog/BlogMenu';
import RandomGenerator from '../content/golfcourses/randomGenerator/RandomGenerator';
import Faqs from '../faqs/Faqs';
import Membership from '../membership/Membership';
import TrustedBy from '../trustedBy/TrustedBy';
import { Link } from "react-router-dom";

//import EnglandEmoji from "../../img/flags/England_emoji_20_12.png";

export default function CompareMenu() {
  const navigate = useNavigate();

  const handleJoinClubClick = () => {
    navigate('/register');
  };

  const handleSearchLists = () => {
    navigate('/lists/golf-courses/rankings');
  };

  return (
    <>
      <section className="xs:w-full w-11/12 mx-auto my-4 flex flex-col justify-center items-center border-2 border-indigo-800 rounded-lg sm:w-2/3 md:w-2/3 lg:w-1/2 p-4">
        <h1 className="text-center font-bold xs:text-lg text-2xl  sm:text-4xl max-w-md">
          The search engine for local golf information...
        </h1>
        <div className="w-full text-center">
          <h2 className=" mx-auto text-indigo-600 xs:text-base text-lg sm:text-xl font-bold my-1 max-w-lg">
            Powered by a community of local golf experts and explorers...
          </h2>
          <ul className="text-left xs:text-xs list-disc my-1 sm:my-4 pl-4 pr-1 text-sm sm:text-base text-gray-600 sm:max-w-xl mx-auto sm:pl-14 md:pl-20">
            <li className="mt-2">Search golf courses, clubs, opens and more</li>
            <li className="mt-2">Map your golf course played list</li>
            <li className="mt-2">Create and share your golf course rankings</li>
            <li className="mt-2">See your personal golfing dashboard</li>
            <li className="mt-2">
              Find fellow club golfers for reciprocal games
            </li>
            <li className="mt-2">Join a community of "golf list chasers"</li>
          </ul>
          <button
            onClick={handleJoinClubClick}
            className="w-full md:h-16 bg-indigo-800 text-white rounded-md p-2 my-1 hover:bg-indigo-600 border-2 border-white max-w-md"
            href="/register"
          >
            Join Golf Lists for free
          </button>
          <button
            onClick={handleSearchLists}
            className="w-full md:h-16 bg-green-800 text-white rounded-md p-2 my-1 hover:bg-green-600 border-2 border-white max-w-md"
            href="/lists/golf-courses/rankings"
          >
            Search Golf Lists your way
          </button>
        </div>
      </section>
      <hr></hr>
      <TrustedBy />
      <hr></hr>
      <About />
      <hr></hr>
      <Membership level={2} />
      <hr></hr>
      <RandomGenerator />
      <hr></hr>

      <div className="mx-auto max-w-7xl py-6 sm:py-12 px-6 lg:px-8">
        <h2 className="mx-auto max-w-2xl text-center mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          GolfLists Blog
        </h2>
        <p className="mx-auto text-center mt-6 text-base leading-7 text-gray-600">
          Click to <Link className='text-blue-700 underline hover:text-blue-900' to="/blog">read more</Link>.
        </p>
        <BlogMenu numberOfPosts={3} level={2} />
      </div>

      <hr></hr>
      <Faqs level={2} />
      <hr></hr>
    </>
  );
}
