import { createContext, useEffect, useState } from 'react';



import { supabase } from '../utils/supabase';


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [homeGolfClub, setHomeGolfClub] = useState(null);
  const [awayGolfClub, setAwayGolfClub] = useState(null);
  const [editPermissions, setEditPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editableCourses, setEditableCourses] = useState([]);
  const [username, setUsername] = useState(null);
  const [glPoints, setGlPoints] = useState(null);

  const onAuthStateChange = (callback) => {
    let currentSession = null;
    return supabase.auth.onAuthStateChange((event, session) => {
      if (session?.user?.id === currentSession?.user?.id) return;
      currentSession = session;
      callback(event, session);
    });
  };

  useEffect(() => {
    // Check for an existing session immediately upon mounting
    (async () => {
      const savedSession = localStorage.getItem('supabase.auth.token');
      if (savedSession) {
        const parsedSession = JSON.parse(savedSession);
        setUser(parsedSession.user);
        setLoading(false);
      } else {
        const { data: session } = await supabase.auth.getSession();
        if (session) {
          setUser(session.user);
          setLoading(false);
        }
      }
    })();

    const { data: authListener } = onAuthStateChange(async (event, session) => {
      setUser(session?.user || null);
      setLoading(false);

      if (session) {
        localStorage.setItem('supabase.auth.token', JSON.stringify(session));

        try {
          const { data: userProfile, error } = await supabase
            .from('user_profile')
            .select(
              'home_golf_club, away_golf_club, edit_permissions, editable_courses, username, gl_points',
            )
            .eq('user_uid', session.user.id)
            .single();

          if (error) throw error;

          setHomeGolfClub(userProfile?.home_golf_club ?? null);
          setAwayGolfClub(userProfile?.away_golf_club ?? null);
          setEditPermissions(userProfile?.edit_permissions ?? null);
          setEditableCourses(userProfile?.editable_courses ?? []);
          setUsername(userProfile?.username ?? null);
          setGlPoints(userProfile?.gl_points ?? null);
        } catch (error) {
          console.error('Error fetching updated user profile:', error);
        }
      } else {
        localStorage.removeItem('supabase.auth.token');
        setHomeGolfClub(null);
        setAwayGolfClub(null);
        setEditPermissions(null);
        setEditableCourses([]);
        setUsername(null);
        setGlPoints(null)
      }
    });

    return () => {
      authListener.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        homeGolfClub,
        awayGolfClub,
        editPermissions,
        editableCourses,
        username,
        isAuthenticated: !!user,
        loading,
        glPoints
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};