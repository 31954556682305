import { configureStore } from '@reduxjs/toolkit';

import currentMenuReducer from '../features/currentMenu/currentMenuSlice';
import currentTabReducer from '../features/currentTab/currentTabSlice';
import lastRefreshTimestampReducer from '../features/lastRefreshTimestamp/lastRefreshTimestampSlice';
import pageIndexReducer from '../features/pageIndex/pageIndexSlice';
// Import the new slice
import pageSizeReducer from '../features/pageSize/pageSizeXSlice';
import selectedCountryXReducer from '../features/selectedCountryX/selectedCountryXSlice';
import selectedInfoReducer from '../features/selectedInfo/selectedInfoSlice';
import selectedListReducer from '../features/selectedList/selectedListSlice';

export default configureStore({
  reducer: {
    selectedCountryX: selectedCountryXReducer,
    selectedList: selectedListReducer,
    currentTab: currentTabReducer,
    selectedInfo: selectedInfoReducer,
    currentMenu: currentMenuReducer, // Add the new slice to your Redux store
    pageSizeX: pageSizeReducer,
    pageIndex: pageIndexReducer,
    lastRefreshTimestamp: lastRefreshTimestampReducer,
  },
});
