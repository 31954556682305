import React from 'react';

import homeRefresh from '../../helpers/homeRefresh';
import Logo from '../../img/brand/fulllogo_nobuffer.png';
import Layout from '../layout/Layout';

function Error() {
  const handleHomeClick = () => {
    homeRefresh();
  };
  return (
    <div>
      <Layout>
        <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8 min-h-screen">
          <img
            loading="lazy"
            className="mx-auto h-10 w-auto sm:h-12"
            src={Logo}
            alt="Your Company"
          />
          <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
            <h1 className="mt-4 text-base sm:text-3xl font-bold tracking-tight text-gray-900">
              Oops, there was an error - please go back to the homepage.
            </h1>
            <button
              onClick={handleHomeClick}
              className="mt-4 h-fit relative inline-flex items-center px-3 py-2 text-base font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-gray-900 focus:z-10  bg-indigo-600 rounded-md"
            >
              Home
            </button>

            <p className="mt-4 text-xs leading-7 text-gray-600 sm:mt-6 sm:text-base sm:leading-8">
              We will fix this issue as soon as possible.
            </p>
          </div>
        </main>
      </Layout>
    </div>
  );
}

export default Error;
