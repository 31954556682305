import React from 'react';

import NotFound from '../components/error/NotFound';
import Footer from '../components/footer/Footer';
import MetaTags from '../components/meta/MetaTags';
import ListsTabMenu from '../components/navbar/ListsTabMenu';

function NotFoundPage() {
  return (
    <div>
      <MetaTags
        title="Not Found Page"
        description="This GolfLists.com page could not be found."
        imageUrl="https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/brand/GL_LOGO_300_302.png"
        keywords="GolfLists, Not Found Page"
      />
      <ListsTabMenu />
      <NotFound />
      <hr></hr>
      <Footer />
    </div>
  );
}

export default NotFoundPage;
