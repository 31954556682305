import React from 'react';
import { useNavigate } from 'react-router-dom';

const ToolsSubMenu = ({ handleToggle }) => {
  const navigate = useNavigate();

  const tools = [
    { name: 'Golf Trip Planner', path: '/lists/tools/golf-trips' },
    { name: 'Open Competition Search', path: '/lists/tools/opens' },
    {
      name: 'Random Golf Course Generator',
      path: '/lists/tools/random-golf-course-generator',
    },
  ];

  const handleNavigation = (path) => {
    // Close the modal first
    if (handleToggle) {
      handleToggle();
      navigate(path);
    } else {
      navigate(path);
    }
  };

  return (
    <div className="z-100 bg-white text-left grid grid-cols-1 sm:grid-cols-3 w-full sm:w-screen sm:rounded sm:shadow-lg p-1 md:px-96">
      {tools.map((tool) => (
        <button
          key={tool.name}
          onClick={() => handleNavigation(tool.path)}
          className="w-full mx-auto flex flex-col flex-nowrap justify-center items-start sm:items-center py-2 font-bold text-left text-sm sm:text-base lg:text-base text-gray-700 hover:bg-gray-100 rounded px-1 hover:underline"
        >
          {tool.name}
        </button>
      ))}
    </div>
  );
};

export default ToolsSubMenu;
