import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import { setSelectedCountryX } from '../../../../features/selectedCountryX/selectedCountryXSlice';
import '../../../../index.css';
import { fetchGolfCourses } from '../../../../utils/fetchGolfCourses';
import fetchNumberCourses from '../../../../utils/fetchNumberCourses';
import tigerQuote from './tigerquote.wav';

//import { slugify } from "../../../../utils/slugify";

function RandomGenerator() {
  const [randomCourse, setRandomCourse] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [courseCounts, setCourseCounts] = useState({
    England: 0,
    Scotland: 0,
    Wales: 0,
    'Northern Ireland': 0,
    Ireland: 0,
    USA: 0,
  });
  const [totalCourses, setTotalCourses] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // react-spring transition
  const transitions = useTransition(randomCourse, {
    from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
  });

  useEffect(() => {
    if (isGenerating) {
      const interval = setInterval(() => {
        getRandomCourse();
      }, 50);

      setTimeout(() => {
        clearInterval(interval);
        setIsGenerating(false);
      }, 2000);
    }
  }, [isGenerating]);

  async function getRandomCourse() {
    try {
      const golfCourses = await fetchGolfCourses();
      const randomIndex = Math.floor(Math.random() * golfCourses.length);
      setRandomCourse(golfCourses[randomIndex]);
    } catch (error) {
      console.error('Error fetching golf courses:', error);
    }
  }

  const handleClick = () => {
    setIsGenerating(true);
    const soundEffect = new Audio(tigerQuote);
    soundEffect.play();
  };

  useEffect(() => {
    const getCoursesCount = async () => {
      const counts = await fetchNumberCourses();
      setCourseCounts(counts.countryCounts);
      setTotalCourses(counts.totalCourses);
    };
    getCoursesCount();
  }, []);

  // The function to handle click on the country div
  const handleCountryClick = (country) => {
    // Dispatching the action to update selectedCountryX
    dispatch(setSelectedCountryX(country));
    // Navigating to the /lists/golf-courses route
    navigate(`/lists/golf-courses/rankings`);
  };

  return (
    <>
      <div className="bg-white py-6 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center"></div>
          <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Golf Adventures
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600 text-center">
            Don't need a good reason...
          </p>
          <Link
            to="/lists/golf-tools/random-golf-course-generator"
            className="flex justify-center text-center text-blue-700 hover:text-blue-900 hover:underline"
          >
            Customise the Generator...
          </Link>
          <div className="flex flex-row justify-center items-center flex-wrap w-full">
            <div className="flex justify-end items-center w-1/2 p-2">
              <button
                className="big-button text-xs p-1 sm:text-sm"
                onClick={handleClick}
              >
                <span className="flex flex-col justify-center items-center h-full px-2">
                  Random Golf Course Generator
                </span>
              </button>
            </div>

            <div className="relative flex flex-row flex-wrap justify-start items-center w-1/2">
              {transitions((styles, item) =>
                item ? (
                  <animated.div
                    className="absolute mt-4 flex flex-col bg-white rounded shadow-2xl border border-gray-300 w-fit min-w-[100px]  max-w-[150px] sm:min-w-[250px] sm:min-h-[100px] p-2 items-center justify-center"
                    style={styles}
                  >
                    <Link
                      className="text-xs text-center sm:text-lg font-bold mb-2 hover:underline"
                      to="/lists/tools/random-golf-course-generator"
                    >
                      {item.course_name}
                    </Link>
                    <p className="text-xs sm:text-sm text-gray-600">
                      {item.course_county}
                    </p>
                  </animated.div>
                ) : (
                  ''
                ),
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-12 sm:pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Insider Golf Insights
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600 text-center">
              For {totalCourses} courses, and counting...
            </p>
          </div>
        </div>
        <div className="mt-10 bg-white pb-12 sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2" />
            <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-4xl">
                <dl className="rounded-lg bg-white shadow-lg grid grid-cols-2 sm:grid-cols-3">
                  <div
                    className="flex flex-col border-b border-gray-100 hover:bg-slate-300 p-6 text-center sm:border-0 sm:border-r cursor-pointer"
                    onClick={() => handleCountryClick('england')}
                  >
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-600">
                      England
                    </dt>
                    <dd className="order-1 text-lg md:text-5xl font-bold tracking-tight text-indigo-600">
                      {courseCounts.England}
                    </dd>
                  </div>
                  <div
                    className="flex flex-col border-b border-t border-gray-100 hover:bg-slate-300 p-6 text-center sm:border-0 sm:border-l sm:border-r cursor-pointer"
                    onClick={() => handleCountryClick('scotland')}
                  >
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-600">
                      Scotland
                    </dt>
                    <dd className="order-1 text-lg md:text-5xl font-bold tracking-tight text-indigo-600">
                      {courseCounts.Scotland}
                    </dd>
                  </div>
                  <div
                    className="flex flex-col border-t border-gray-100 hover:bg-slate-300 p-6 text-center sm:border-0 sm:border-l cursor-pointer"
                    onClick={() => handleCountryClick('ireland')}
                  >
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-600">
                      Ireland
                    </dt>
                    <dd className="order-1 text-lg md:text-5xl font-bold tracking-tight text-indigo-600">
                      {courseCounts.Ireland}
                    </dd>
                  </div>
                  <div
                    className="flex flex-col border-t border-gray-100 hover:bg-slate-300 p-6 text-center sm:border-0 sm:border-l cursor-pointer"
                    onClick={() => handleCountryClick('wales')}
                  >
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-600">
                      Wales
                    </dt>
                    <dd className="order-1 text-lg md:text-5xl font-bold tracking-tight text-indigo-600">
                      {courseCounts.Wales}
                    </dd>
                  </div>
                  <div
                    className="flex flex-col border-t border-gray-100 hover:bg-slate-300 p-6 text-center sm:border-0 sm:border-l cursor-pointer"
                    onClick={() => handleCountryClick('ni')}
                  >
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-600">
                      Northern Ireland
                    </dt>
                    <dd className="order-1 text-lg md:text-5xl font-bold tracking-tight text-indigo-600">
                      {courseCounts['Northern Ireland']}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RandomGenerator;
