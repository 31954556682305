import { supabase } from './supabase';

const fetchNumberCourses = async () => {
  const countries = [
    'England',
    'Scotland',
    'Wales',
    'Northern Ireland',
    'Ireland',
    'USA',
  ];

  let coursesPerCountry = {};
  let totalCourses = 0;

  for (const country of countries) {
    const { data: courses, error } = await supabase
      .from('golf_courses')
      .select('course_name')
      .eq('course_country', country);

    if (error) {
      console.error(`Error fetching courses for ${country}`, error);
      continue;
    }

    coursesPerCountry[country] = courses.length;
    totalCourses += courses.length;
  }

  return {
    countryCounts: coursesPerCountry,
    totalCourses: totalCourses,
  };
};

export default fetchNumberCourses;
