import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { supabase } from '../../utils/supabase';

function NewsletterSignup() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();

    // Check if email is entered
    if (!email) {
      alert('Please enter an email');
      return;
    }

    try {
      // Insert the newsletter entry into the database
      const { error } = await supabase
        .from('waitlist_emails')
        .insert([{ waitlist_email: email, waitlist_golf_club: 'newsletter' }]);

      if (error) {
        //console.log("Error inserting newsletter entry:", error);

        // check the error code and customize the error message
        if (error.code === '23505') {
          alert('This email is already on the newsletter list');
        } else {
          alert('An error occurred while signing up for the newsletter');
        }

        return;
      }

      // Clear the email input after successful submission
      setEmail('');

      // Show a thank you alert after a successful submission
      alert(
        'Thanks for signing up for the newsletter! Check your inbox for updates.',
      );

      // Redirect to the home page or any other desired page
      navigate('/');
    } catch (error) {
      //console.log("Error signing up for the newsletter:", error);
      alert('An error occurred while signing up for the newsletter');
    }
  };

  return (
    <>
      <div className="p-4 max-w-xs sm:max-w-2xl mx-auto">
        <h2 className="text-lg font-bold py-2">
          Would you like to play better golf cheaper?
        </h2>
        <p className="text-slate-700 text-sm ">
          Join golfers around the UK & Ireland getting hidden golf deals sent to
          their inbox every month.
        </p>
        <form className="" onSubmit={handleSignup}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full h-12 rounded-md p-2 my-2 border border-gray-300"
          />
          <button
            type="submit"
            className="w-full h-12 bg-indigo-600 text-white rounded-md p-2 my-2 hover:bg-indigo-900"
          >
            Play better golf cheaper!
          </button>
        </form>
      </div>
    </>
  );
}

export default NewsletterSignup;
