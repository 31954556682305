import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { supabase } from '../../utils/supabase';

const CountySubMenuCourses = ({ handleToggle }) => {
  const [countiesByCountry, setCountiesByCountry] = useState({});
  const navigate = useNavigate();
  //console.log("ht",handleToggle)

  useEffect(() => {
    const fetchCounties = async () => {
      try {
        const countries = [
          'England',
          'Scotland',
          'Wales',
          'Northern Ireland',
          'Ireland',
        ];
        let results = {};

        for (const country of countries) {
          const { data, error } = await supabase
            .from('counties')
            .select('county_name, slug_county_name')
            .eq('country_of_county', country)
            .order('county_name');

          if (error) throw error;
          if (data) {
            results[country] = data;
          }
        }

        setCountiesByCountry(results);
      } catch (error) {
        console.error('Error fetching counties:', error);
      }
    };

    fetchCounties();
  }, []);

  const handleNavigation = (path) => {
    // Close the modal first
    if (handleToggle) {
      handleToggle();
      navigate(path);
    } else {
      navigate(path);
    }
  };

  if (!Object.keys(countiesByCountry).length) return null;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-5 z-100 bg-white border w-full sm:w-screen sm:rounded sm:shadow-lg px-4">
      {Object.entries(countiesByCountry).map(([country, counties]) => (
        <div
          key={country}
          className="flex flex-col flex-nowrap justify-start items-center border border-slate-50"
        >
          <button
            onClick={() => handleNavigation(`/lists/golf-courses/rankings`)}
            className="font-bold text-lg mb-1 text-center hover:underline"
          >
            {country}
          </button>
          <div className="w-full grid grid-cols-2">
            {counties.map((county) => (
              <button
                key={county.slug_county_name}
                onClick={() =>
                  handleNavigation(
                    `/lists/golf-courses/county-ranking/${county.slug_county_name}`,
                  )
                }
                className="text-left truncate text-xxs sm:text-xs lg:text-sm text-gray-700 hover:bg-gray-100 rounded px-1 hover:underline"
              >
                {county.county_name}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CountySubMenuCourses;
