import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CountySubMenuCourses from './CountySubMenuCourses';
import ToolsSubMenu from './ToolsSubMenu';
import { tabs } from './tabs';

const ListsTabMenuMobile = ({ handleToggle }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuType, setSubMenuType] = useState('');
  const navigate = useNavigate();

  const toggleSubMenu = (e, tabName) => {
    // Check if tab has a submenu before toggling
    if (['Rankings', 'Tools'].includes(tabName)) {
      e.stopPropagation();
      if (subMenuType === tabName) {
        setShowSubMenu(!showSubMenu);
      } else {
        setSubMenuType(tabName);
        setShowSubMenu(true);
      }
    }
  };

  const handleTabClick = (link) => {
    navigate(link);
    handleToggle();
  };

  return (
    <div className="bg-white flex flex-col w-full border border-gray-300 items-center justify-center space-y-4 p-4">
      {tabs.map((tab) => (
        <div key={tab.name} className="w-full">
          <div
            className="flex items-center justify-between w-full p-2 rounded-md hover:bg-gray-100"
            onClick={() => handleTabClick(tab.link)}
          >
            <span className="text-indigo-700 font-medium">{tab.name}</span>
            {['Rankings', 'Tools'].includes(tab.name) ? (
              <button
                onClick={(e) => toggleSubMenu(e, tab.name)}
                className="text-indigo-500"
              >
                <FontAwesomeIcon
                  icon={
                    showSubMenu && subMenuType === tab.name
                      ? faChevronUp
                      : faChevronDown
                  }
                />
              </button>
            ) : (
              <span className="text-indigo-500" /> // Empty span for alignment
            )}
          </div>
          {showSubMenu && subMenuType === tab.name && (
            <div className="mt-2 bg-gray-50 rounded-md">
              {subMenuType === 'Rankings' && (
                <CountySubMenuCourses handleToggle={handleToggle} />
              )}
              {subMenuType === 'Tools' && (
                <ToolsSubMenu handleToggle={handleToggle} />
              )}
              {/* Add other submenus as needed */}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ListsTabMenuMobile;
