import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';



import App from './App';
import store from './app/store';
import Error from './components/error/Error';
import LoadingScreen from './components/loading/LoadingScreen';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import RootRedirect from './components/signin/RootRedirect';
import { AuthProvider } from './context/AuthContext';
import ScrollToTop from './hooks/ScrollToTop';
import './index.css';
import NotFoundPage from './pages/NotFoundPage';


const GolfCourseRankingsPage = lazy(
  () => import('./pages/GolfCourseRankingsPage'),
);
const GolfCourseInfoPage = lazy(() => import('./pages/GolfCourseInfoPage'));
const ClubMembershipsPage = lazy(() => import('./pages/ClubMembershipsPage'));
const ConnectPage = lazy(() => import('./pages/ConnectPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const AmbassadorPage = lazy(() => import('./pages/AmbassadorPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const RegisterPage = lazy(() => import('./pages/RegisterPage'));
const SignInPage = lazy(() => import('./pages/SignInPage'));
const BlogPage = lazy(() => import('./pages/BlogPage'));
const BlogPostPage = lazy(() => import('./pages/BlogPostPage'));
const NewsletterPostPage = lazy(() => import('./pages/NewsletterPostPage'));
const FaqPage = lazy(() => import('./pages/FaqPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const MembershipPage = lazy(() => import('./pages/MembershipPage'));
const ListsPage = lazy(() => import('./pages/ListsPage'));
const GolfTripsPage = lazy(() => import('./pages/GolfTripsPage'));
const OpensPage = lazy(() => import('./pages/OpensPage'));
const ClubOpensPage = lazy(() => import('./pages/ClubOpensPage'));
const GolfPracticePage = lazy(() => import('./pages/GolfPracticePage'));
const GolfEntertainmentPage = lazy(
  () => import('./pages/GolfEntertainmentPage'),
);
const GolfToolsPage = lazy(() => import('./pages/GolfToolsPage'));
const TestPage = lazy(() => import('./pages/TestPage'));
const GolfCoursePage = lazy(() => import('./pages/GolfCoursePage'));
const GolfClubPage = lazy(() => import('./pages/GolfClubPage'));
const TermsPage = lazy(() => import('./pages/TermsPage'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const PublicProfilePage = lazy(() => import('./pages/PublicProfilePage'));
const ConfirmRegisterPage = lazy(() => import('./pages/ConfirmRegisterPage'));
const ConfirmedPage = lazy(() => import('./pages/ConfirmedPage'));
const CourseSitemapPage = lazy(() => import('./pages/CourseSitemapPage'));
const CountyPage = lazy(() => import('./pages/CountyPage'));
const CountySitemapPage = lazy(() => import('./pages/CountySitemapPage'));
const CollectionsSitemapPage = lazy(
  () => import('./pages/CollectionsSitemapPage'),
);
const CollectionsPage = lazy(() => import('./pages/CollectionsPage'));

const ProfilePageTab = lazy(
  () => import('./components/profile/ProfilePageTab'),
);
const EditProfileTab = lazy(
  () => import('./components/profile/EditProfileTab'),
);
const PlayedListTab = lazy(() => import('./components/profile/PlayedListTab'));
const BucketListTab = lazy(() => import('./components/profile/BucketListTab'));
const ContributeTab = lazy(() => import('./components/profile/ContributeTab'));

const root = ReactDOM.createRoot(document.getElementById('root'));

function ErrorFallback({ error, resetErrorBoundary }) {
  return <Error />;
}

root.render(
  <>
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter>
          <ScrollToTop />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<LoadingScreen type="spin" color="#000" />}>
              <RootRedirect />
              <Routes>
                <Route path="/" element={<App />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/signin" element={<SignInPage />} />
                <Route
                  path="/confirmregister"
                  element={<ConfirmRegisterPage />}
                />
                <Route path="/confirmed" element={<ConfirmedPage />} />

                {/*Lists Routes*/}
                <Route path="/lists" element={<ListsPage />}>
                  {/*List Variations*/}
                  <Route
                    path="/lists/golf-courses/rankings"
                    element={<GolfCourseRankingsPage />}
                  />
                  <Route
                    path="/lists/golf-courses/golf-course-info"
                    element={<GolfCourseInfoPage />}
                  />
                  <Route
                    path="/lists/golf-clubs/club-memberships"
                    element={<ClubMembershipsPage />}
                  />

                  <Route path="golf-practice" element={<GolfPracticePage />} />
                  <Route
                    path="/lists/tools/golf-trips"
                    element={<GolfTripsPage />}
                  />
                  <Route path="/lists/tools/opens" element={<OpensPage />} />
                  <Route
                    path="/lists/opens/:slugClubName"
                    element={<ClubOpensPage />}
                  />
                  <Route
                    path="/lists/tools/random-golf-course-generator"
                    element={<GolfToolsPage />}
                  />

                  <Route
                    path="golf-entertainment"
                    element={<GolfEntertainmentPage />}
                  />
                  <Route
                    path="/lists/golf-courses/county-ranking/:countySlug"
                    element={<CountyPage />}
                  />
                  <Route
                    path="/lists/golf-courses/collections/:collectionSlug"
                    element={<CollectionsPage />}
                  />
                </Route>

                <Route
                  path="/lists/golf-courses/:courseSlug" //slugified
                  element={<GolfCoursePage />}
                />

                <Route
                  path="/lists/golf-clubs/:clubSlug" //slugified New
                  element={<GolfClubPage />}
                />

                <Route
                  path="/profile/:username"
                  element={<PublicProfilePage />}
                />
                <Route
                  path="/connect"
                  element={<PrivateRoute element={<ConnectPage />} />}
                />

                <Route
                  path="/profile"
                  element={<PrivateRoute element={<ProfilePage />} />}
                >
                  <Route index element={<ProfilePageTab />} />
                  <Route path="profile-page" element={<ProfilePageTab />} />
                  <Route path="edit-profile" element={<EditProfileTab />} />
                  <Route path="played-list" element={<PlayedListTab />} />
                  <Route path="bucket-list" element={<BucketListTab />} />
                  <Route path="contribute" element={<ContributeTab />}/>
                </Route>

                <Route path="/about" element={<AboutPage />} />
                <Route path="/ambassador" element={<AmbassadorPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:blogurl" element={<BlogPostPage />} />
                <Route
                  path="/blog/newsletter/:newsurl"
                  element={<NewsletterPostPage />}
                />
                <Route path="/faqs" element={<FaqPage />} />
                <Route path="/membership" element={<MembershipPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route
                  path="/golfcoursesitemap"
                  element={<CourseSitemapPage />}
                />
                <Route
                  path="/golfcountysitemap"
                  element={<CountySitemapPage />}
                />
                <Route
                  path="/golfcollectionssitemap"
                  element={<CollectionsSitemapPage />}
                />
                <Route path="/test" element={<TestPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </AuthProvider>
    </Provider>
    <ToastContainer />
  </>,
);