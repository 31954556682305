import React from 'react';

import { createTitleComponent } from '../../helpers/createTitleComponent';

const faqsContent = [
  {
    id: 1,
    question: 'But what actually is GolfLists.com?',
    answer:
      'GolfLists.com is a massive database of local golf insights shared by a network of British and Irish golf club members!',
  },
  {
    id: 2,
    question: 'What is the vision for GolfLists.com?',
    answer:
      'To become the go to community for British and Irish Golf Club members to connect and share or access insider golf insights.',
  },
  {
    id: 3,
    question: 'What makes GolfLists.com different to other golf sites?',
    answer:
      'GolfLists.com is focussed on connection, providing user tools and comparison of objective information; rather than visitor reviews.',
  },
  {
    id: 4,
    question: 'Can I partner with, or write about GolfLists.com?',
    answer:
      'For partnership, press or general enquiries, please contact us via the contact page.',
  },
  {
    id: 5,
    question: 'How does GolfLists.com collect its data?',
    answer:
      'GolfLists.com collects golf course information through crowdsourced inputs to our datasets and through manual data entry by our research team.',
  },
  {
    id: 6,
    question: 'Who started GolfLists.com?',
    answer:
      'A golf nerd and 6 handicap golfer from the UK. He got the idea for GL when planning a golf trip to Scotland he was fed up of opening 15+ websites, it spiralled from there!',
  },
];

export default function Faqs({ level }) {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-6 sm:py-12 px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          {createTitleComponent(
            level,
            'FAQs',
            'mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl',
          )}
          <p className="mt-6 text-base leading-7 text-gray-600">
            Have a different question? Reach the team by sending us an email and
            we will get back to you as soon as we can.
          </p>
        </div>
        <div className="mt-20">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
            {faqsContent.map((faq) => (
              <div key={faq.id}>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {faq.question}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {faq.answer}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
