import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';
import LoadingScreen from '../loading/LoadingScreen';

const PrivateRoute = ({ element }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingScreen type="spin" color="#000" />;
  }

  return user ? element : <Navigate to="/signin" replace state={{ from: window.location.pathname }} />;
};

export default PrivateRoute;
