import React from 'react';
import ReactLoading from 'react-loading';

const LoadingScreen = ({ type, color }) => (
  <div className="flex flex-col justify-center items-center h-full w-full my-2 min-h-[200px] px-4">
    <p className="w-full mx-auto text-center italic text-white">
      We hate slow play, but crunching all this data takes some time to load!
      Sorry.
    </p>
    <ReactLoading type={type} color={color} height={'5%'} width={'5%'} />
  </div>
);

export default LoadingScreen;
