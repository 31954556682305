import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';

import { useState } from 'react';

import { createTitleComponent } from '../../helpers/createTitleComponent';

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '' },
  { value: 'annually', label: 'Annually', priceSuffix: '' },
];
const tiers = [
  {
    name: 'GolfLists Club',
    id: 'tier-free',
    href: '/register',
    price: { monthly: 'Free', annually: 'Free' },
    description:
      'Perfect for the club golfer hunting down the top 100 or expanding their golf network.',
    features: [
      'Add and discover local knowledge in our golf lists',
      'Record your course played and bucket lists',
      'Create and share your own course rankings',
      'Share your personal golfing dashboard',
      'Connect with club golfers for matches',
      'Control which club members can contact you',
      'Access to balloted in-person golf events and deals',
      'Beta user status, special benefits for our first 1000 members!',
    ],
    mostPopular: true,
  },
  /*
  {
    name: "Premium Club",
    id: "tier-premium",
    href: "#",
    price: { monthly: "£9", annually: "£59" },
    description:
      "Perfect for the golf brain who loves golf data, finding great deals and playing great courses all year round.",
    features: [
      "All Intro Club features",
      "Contact reciprocal matches",
      "Access to premium datasets",
      "Premium Club Discord access",
      "Access to in-person events",
      "Access to partner discounts and giveaways",
    ],
    mostPopular: true,
  },
  */
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Membership({ level }) {
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <div className="bg-white py-6 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          {createTitleComponent(
            level,
            'Membership',
            'mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl',
          )}
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-sm sm:text-lg leading-8 text-gray-600">
          Sign up today for free.
        </p>
        <div className="mt-6 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-indigo-600 text-white' : 'text-gray-600',
                    'cursor-pointer rounded-full py-1 px-2.5',
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 lg:mx-auto lg:max-w-xl">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.id === 'tier-premium'
                  ? 'ring-2 ring-indigo-600'
                  : 'ring-1 ring-gray-200',
                'rounded-3xl p-8 xl:p-10',
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.id === 'tier-premium' && frequency.value === 'annually'
                      ? 'text-indigo-600'
                      : 'text-gray-900',
                    'text-md sm:text-lg font-semibold leading-8',
                  )}
                >
                  {tier.name}
                </h3>
                {tier.id === 'tier-premium' &&
                frequency.value === 'annually' ? (
                  <p className="rounded-md bg-indigo-600/10 py-1 px-2.5 text-xs font-semibold leading-5 text-indigo-600">
                    Best Value
                  </p>
                ) : null}
              </div>
              <p className="mt-1 sm:mt-4 text-xs sm:text-sm leading-4 sm:leading-6 text-gray-600">
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-600">
                  {frequency.priceSuffix}
                </span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-600'
                    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                )}
              >
                Join the club
              </a>
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li
                    key={feature}
                    className="flex gap-x-3 text-xs sm:text-base"
                  >
                    <CheckIcon
                      className="h-6 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
