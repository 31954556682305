import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({
  title = 'GolfLists.com - huge lists of golf information for the British Isles',
  description = 'Golflists.com is a huge database of local golf insights made for top 100 golf travelers, historians, nerds, tickers, default etc.',
  imageUrl = 'https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/brand/GL_LOGO_300_302.png', // Replace with your default image URL
  url = 'https://golflists.com',
  keywords = 'golf, golf travel, golf history, golf courses, golf clubs,top 100, UK, Ireland, Scotland, Wales, County, Rankings',
}) => {
  return (
    <Helmet>
      <title key="title">{title}</title>
      <meta name="description" content={description} key="description" />
      <meta name="keywords" content={keywords} key="keywords" />
      <meta property="og:title" content={title} key="og:title" />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta property="og:image" content={imageUrl} key="og:image" />
      <meta property="og:url" content={url} key="og:url" />
      <meta property="og:type" content="website" key="og:type" />
      <meta name="twitter:card" content="summary" key="twitter:card" />
      <meta name="twitter:title" content={title} key="twitter:title" />
      <meta
        name="twitter:description"
        content={description}
        key="twitter:description"
      />
      <meta name="twitter:image" content={imageUrl} key="twitter:image" />
      <meta name="twitter:site" content="@golflists" key="twitter:site" />
      <meta name="twitter:creator" content="@golflists" key="twitter:creator" />
      <link rel="canonical" href={url} key="canonical" />
    </Helmet>
  );
};

export default MetaTags;
