// lastRefreshTimestampSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const lastRefreshTimestampSlice = createSlice({
  name: 'lastRefreshTimestamp',
  initialState: {
    value: 0,
  },
  reducers: {
    setLastRefreshTimestamp: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setLastRefreshTimestamp } = lastRefreshTimestampSlice.actions;

export default lastRefreshTimestampSlice.reducer;
