import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function RootRedirect() {
  const navigate = useNavigate();
  const location = useLocation();
  //console.log("RootRedirect component rendered");

  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.endsWith('/#')) {
      //console.log("Attempt to redirect from /# to /profile");
      navigate('/profile');
    }
  }, [navigate, location]);

  return null; // This component doesn't render anything
}

export default RootRedirect;
