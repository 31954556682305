import { createSlice } from '@reduxjs/toolkit';

export const currentMenuSlice = createSlice({
  name: 'currentMenu',
  initialState: {
    value: 'Home', // Initialize with 'Home'
  },
  reducers: {
    setCurrentMenu: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCurrentMenu } = currentMenuSlice.actions;

export default currentMenuSlice.reducer;
