import { createSlice } from '@reduxjs/toolkit';

export const selectedListSlice = createSlice({
  name: 'selectedList',
  initialState: 'Dashboard',
  reducers: {
    setDashboard: (state) => 'Dashboard',
    setPlayed: (state) => 'Played List',
    setBucket: (state) => 'Bucket List',
  },
});

export const { setPlayed, setBucket, setDashboard } = selectedListSlice.actions;

export const selectSelectedList = (state) => state.selectedList;

export default selectedListSlice.reducer;
