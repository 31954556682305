import { supabase } from './supabase';

export const fetchGolfCourses = async (
  filters = { field: 'course_country', operation: 'eq', value: 'England' },
) => {
  const { data, error } = await supabase
    .from('golf_courses')
    .select()
    .filter(filters.field, filters.operation, filters.value)
    .order('england_rank_gl', { ascending: true }) // Add this line to sort by rank
    .limit(1000); // Update the limit to 200

  if (error) {
    throw error;
  }

  return data;
};
