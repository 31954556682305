import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CountySubMenuCourses from './CountySubMenuCourses';
// Adjusted import
import ToolsSubMenu from './ToolsSubMenu';
import { tabs } from './tabs';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ListsTabMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState('');
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuType, setSubMenuType] = useState(''); // Track which submenu to show

  useEffect(() => {
    const path = location.pathname;
    const foundTab = tabs.find((tab) => path.startsWith(tab.link));
    setCurrentTab(foundTab ? foundTab.name : '');
  }, [location.pathname]);

  const handleTabClick = (tabName, tabLink) => {
    navigate(tabLink);
    setCurrentTab(tabName);
  };

  const handleMouseEnterTab = (tabName) => {
    setShowSubMenu(true);
    setSubMenuType(tabName);
  };

  // This function is no longer immediately hiding the submenu when leaving a tab.
  // Instead, the submenu itself will handle when it should be hidden.
  const handleMouseLeaveTab = () => {
    // Intentionally left blank to prevent immediate hiding when leaving the tab.
  };

  const handleMouseEnterSubmenu = () => {
    setShowSubMenu(true); // Ensure the submenu stays open when hovered over.
  };

  const handleMouseLeaveSubmenu = () => {
    setShowSubMenu(false);
    setSubMenuType('');
  };

  return (
    <div className="bg-indigo-100 sm:z-50 border-b border-indigo-300 sm:sticky sm:top-0 sm:w-full sm:h-fit sm:flex sm:flex-col sm:items-center sm:mx-auto">
      <div className="relative w-full max-w-5xl px-4 sm:px-6 lg:px-8">
        <nav
          className="flex space-x-1 sm:space-x-8 justify-center"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onMouseEnter={() => handleMouseEnterTab(tab.name)}
              onMouseLeave={handleMouseLeaveTab}
              onClick={() => handleTabClick(tab.name, tab.link)}
              className={classNames(
                tab.name === currentTab
                  ? 'border-indigo-500 border-b-2 text-gray-900'
                  : 'border-transparent border-b-2 text-gray-600 hover:border-gray-300',
                'whitespace-nowrap px-1 py-2 text-xs sm:text-sm font-medium',
              )}
              aria-current={tab.name === currentTab ? 'page' : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {showSubMenu && (
        <div
          className="absolute left-1/2 transform -translate-x-1/2 top-full"
          onMouseEnter={handleMouseEnterSubmenu}
          onMouseLeave={handleMouseLeaveSubmenu}
        >
          {subMenuType === 'Rankings' && <CountySubMenuCourses />}
          {subMenuType === 'Tools' && <ToolsSubMenu />}
        </div>
      )}
    </div>
  );
}

export default ListsTabMenu;
