import { createSlice } from '@reduxjs/toolkit';

export const currentTabSlice = createSlice({
  name: 'currentTab',
  initialState: 'Profile',
  reducers: {
    setTab: (state, action) => action.payload,
  },
});

export const { setTab } = currentTabSlice.actions;

export const selectCurrentTab = (state) => state.currentTab;

export default currentTabSlice.reducer;
