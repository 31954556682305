import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { slugify } from '../../utils/slugify';
import GolfCourseSelect from '../input/GolfCourseSelect';

function SearchBar() {
  const navigate = useNavigate();

  const handleCourseSelect = (course) => {
    if (course && course.value) {
      navigate(`/lists/golf-courses/${slugify(course.value)}`);
    }
  };

  return (
    <>
      <div className="mx-auto w-full  px-4 py-2 bg-indigo-100">
        <div className="mx-auto w-full max-w-4xl flex flex-row justify-center items-center">
          <FontAwesomeIcon
            className="text-lg sm:text-2xl mr-2 text-indigo-900"
            icon={solid('magnifying-glass')}
          />
          <div className="w-full">
            <GolfCourseSelect
              onChange={handleCourseSelect}
              placeholder="Search courses..."
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchBar;
