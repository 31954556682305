import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

//import BackNavbar from "../src/components/navbar/BackNavbar";
import CompareMenu from '../src/components/compareMenu/CompareMenu';
import CookieConsent from './components/cookies/CookieConsent';
import Layout from './components/layout/Layout';
import MetaTags from './components/meta/MetaTags';

ReactGA.initialize('G-NXE1XLG75C'); // my Google Tracking ID

function App() {
  const location = useLocation(); // Get the current location

  // Send a pageview hit to Google Analytics whenever the location changes
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className="App">
      <MetaTags
        title="Homepage - GolfLists.com"
        description="Golflists.com is a huge database of local golf insights made for golf travelers, historians, nerds, tickers etc."
        imageUrl="https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/brand/GL_LOGO_300_302.png"
        url="https://golflists.com"
        keywords="GolfLists, About, Ambassador"
      />
      <Layout>
        <CompareMenu />
        <CookieConsent />
      </Layout>
    </div>
  );
}

export default App;
