import { createSlice } from '@reduxjs/toolkit';

const initialState = 0; // Default to page 1

export const pageIndexSlice = createSlice({
  name: 'pageIndex',
  initialState,
  reducers: {
    setPageIndex: (state, action) => action.payload,
  },
});

export const { setPageIndex } = pageIndexSlice.actions;

export const selectPageIndex = (state) => state.pageIndex;

export default pageIndexSlice.reducer;
