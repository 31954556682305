import { createSlice } from '@reduxjs/toolkit';

// Safely get item from localStorage and parse it
const getPageSizeFromLocalStorage = () => {
  const storedValue = localStorage.getItem('pageSizeX');
  if (!storedValue) return 10; // Default value
  try {
    return JSON.parse(storedValue);
  } catch (e) {
    console.error('Error parsing pageSizeX from localStorage:', e);
    return 10; // Default value in case of any errors
  }
};

const initialState = getPageSizeFromLocalStorage();
//console.log("Initial pageSizeX from localStorage:", initialState);

export const pageSizeXSlice = createSlice({
  name: 'pageSizeX',
  initialState,
  reducers: {
    setPageSizeX: (state, action) => {
      //console.log("setPageSizeX action payload:", action.payload);
      if (action.payload !== undefined) {
        localStorage.setItem('pageSizeX', JSON.stringify(action.payload));
        return action.payload;
      }
      return state; // Return the current state if action.payload is undefined.
    },
  },
});

export const { setPageSizeX } = pageSizeXSlice.actions;

export const selectPageSizeX = (state) => state.pageSizeX;

export default pageSizeXSlice.reducer;
