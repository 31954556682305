export const tabs = [
  {
    name: 'Rankings',
    link: '/lists/golf-courses/rankings',
    description:
      'Golf Course Rankings cut in many different ways and highly customisable through filters',
    button: 'Explore',
    icon: 'magnifying-glass',
    buttonClass:
      'bg-indigo-700 w-full rounded-md text-white hover:bg-indigo-900 py-2',
  },
  {
    name: 'Golf Course Info',
    link: '/lists/golf-courses/golf-course-info',
    description:
      'Golf Course Info aims to put 1000 golf course websites on one page for you, to help you research, compare and play more golf.',
    button: 'Explore',
    buttonClass:
      'bg-indigo-700 w-full rounded-md text-white hover:bg-indigo-900 py-2',
    icon: 'laugh-beam',
  },
  {
    name: 'Club Memberships',
    link: '/lists/golf-clubs/club-memberships',
    description:
      'Club Memberships is aimed at golfers searching for a new golf club, all the membership data you could want in one big list.',
    button: 'Explore',
    buttonClass:
      'bg-indigo-700 w-full rounded-md text-white hover:bg-indigo-900 py-2',
    icon: 'bullseye',
  },
  {
    name: 'Tools',
    link: '/lists/tools/random-golf-course-generator',
    description:
      'Tools use GolfLists datasets to give build fun tools for the keen golfer, for example our random golf course generator, our trip planner and our open search tool.',
    button: 'Explore',
    buttonClass:
      'bg-indigo-700 w-full rounded-md text-white hover:bg-indigo-900 py-2',
    icon: 'tools',
  },
];
