import React from 'react';

import HorizontalNewsletterSignup from '../blog/HorizontalNewsletterSignup';
import Footer from '../footer/Footer';
import BackNavbar from '../navbar/BackNavbar';
import ListsTabMenu from '../navbar/ListsTabMenu';
import SearchBar from '../navbar/SearchBar';

function Layout({ children }) {
  return (
    <div className="min-h-screen">
      <BackNavbar />
      <SearchBar />
      <ListsTabMenu />
      {children}
      <hr></hr>
      <HorizontalNewsletterSignup />
      <Footer />
    </div>
  );
}

export default Layout;
