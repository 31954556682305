import React, { useEffect, useState } from 'react';

function CookieConsent() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent');
    if (!consent) {
      setShowModal(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'true');
    setShowModal(false);
  };

  if (!showModal) return null;

  return (
    <div className="fixed z-50 bottom-0 left-0 w-full bg-gray-500 bg-opacity-75 flex justify-center items-center p-2">
      <div className="bg-white rounded-lg p-4 w-full border border-black">
        <p className="mb-2 text-center text-black">
          We use cookies to ensure that we give you the best experience on our
          website. Read our{' '}
          <a className="text-indigo-900 underline" href="/terms">
            Terms & Conditions
          </a>{' '}
          and{' '}
          <a className="text-indigo-900 underline" href="/privacy">
            Privacy Policy
          </a>{' '}
          for more details.
        </p>
        <div className="text-center">
          <button
            className="bg-green-900 text-white rounded-md px-4 py-2 hover:bg-green-800"
            onClick={handleAccept}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
}

export default CookieConsent;
