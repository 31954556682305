import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useContext, useState } from 'react';
//import { resetPageIndexSize } from "../../helpers/resetPageIndexSize";
//import GolfCourseSearch from "./GolfCourseSearch";
import { BsChevronLeft } from 'react-icons/bs';
// import NavBarModal
//import { FaDiscord } from "react-icons/fa";
import { useDispatch } from 'react-redux';
// <-- import styles to be used
import { Link, useNavigate } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';
import { setTab } from '../../features/currentTab/currentTabSlice';
//import IconLogo from "../../img/brand/icononly_nobuffer_small.png"; //51x51 px
import TextLogoNoBg from '../../img/brand/textonly_nobuffer_white_nobg.png';
import { supabase } from '../../utils/supabase';
import NavBarModal from './NavBarModal';

//1272 x 67 px

export default function BackNavbar() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const navItemClasses =
    'flex flex-col items-center text-center w-14 text-xs sm:text-base sm:mx-2 hover:animate-pulse hover:cursor-pointer';
  //const [showSearch, setShowSearch] = useState(false);

  const handleProfile = async () => {
    try {
      //await supabase.auth.signOut();
      navigate('/profile'); // Add this line to navigate to the homepage
      dispatch(setTab('Profile')); //set the pink line on profile to main profile
    } catch (error) {
      console.error('Error navigating to profile:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/'); // Add this line to navigate to the homepage
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-indigo-900 top-0">
      {/* The height is the same as GolfCourseNav so that this nav perfectly overlaps the Layout header on scroll */}
      <nav
        className="mx-auto flex max-w-6xl items-center justify-between py-2 px-6 sm:p-6 lg:px-8 h-[65px] sm:h-[67px]"
        aria-label="Global"
      >
        <div className="flex flex-row flex-nowrap justify-start items-center gap-2 md:gap-4 md:p-2">
          {/* Back Button */}
          <button
            className="text-white hover:bg-white hover:text-indigo-900 border rounded sm:bg-indigo-900"
            onClick={() => navigate(-1)}
            aria-label="Go back"
          >
            <BsChevronLeft className="mr-1 md:my-[1px]" />
          </button>
          <Link to={'/'}>
            <div className="flex flex-row flex-nowrap justify-start items-center gap-1">
              <img
                loading="lazy"
                width="1272"
                height="67"
                className="h-5 w-auto md:hidden block" // Tailwind classes to control size
                src={TextLogoNoBg}
                alt="GolfLists.com logo"
              />
              <span className="md:hidden text-xxs inline-flex items-center rounded-sm bg-indigo-50 ml-1 px-[1px] text-xs font-medium text-indigo-700 ring-1 ring-inset ring-red-700/10">
                Beta
              </span>
            </div>
            <div className="flex flex-row flex-nowrap justify-start items-center gap-3">
              <img
                loading="lazy"
                width="1272"
                height="67"
                className="h-7 w-auto hidden md:block" // Tailwind classes to control size
                src={TextLogoNoBg}
                alt="GolfLists.com logo"
              />
              <span className="hidden md:inline-flex items-center rounded-sm bg-indigo-50 px-[3px] text-xs font-medium text-indigo-700 ring-1 ring-inset ring-red-700/10">
                Beta
              </span>
            </div>
          </Link>
        </div>

        <div className="flex flex-row flex-nowrap justify-end items-center sm:hidden min-w-[140px] xs:justify-end">
          {user ? (
            <button
              onClick={handleProfile}
              className="w-10 border-white border text-white rounded-md  my-3 inline-block text-center xs:mr-3 mr-6"
            >
              <FontAwesomeIcon
                className="text-white text-md"
                icon={solid('user')}
              />
            </button>
          ) : (
            <Link
              to={'/signin'}
              className="w-16 text-xs bg-white border border-white text-indigo-900 rounded-md p-1 my-3 inline-block text-center xs:mr-3 mr-4"
            >
              {' '}
              Sign In
            </Link>
          )}
          {/* The mobile menu button */}
          <button onClick={handleToggle} id="al" aria-label="Menu Bars">
            <FontAwesomeIcon icon={solid('bars')} className="text-white" />
          </button>
        </div>

        {/* The desktop menu items */}
        <div className="hidden sm:grid grid-cols-4 gap-1 justify-items-center items-center">
          <Link to={'/'} className={navItemClasses}>
            <FontAwesomeIcon className="text-white" icon={solid('house')} />
            <span className="text-white text-xs">Home</span>
          </Link>

          <Link to={'/connect'} className={navItemClasses}>
            <FontAwesomeIcon className="text-white" icon={solid('comments')} />
            <span className="text-white text-xs">Connect</span>
          </Link>
          <Link
            to={'/profile'}
            onClick={() => {
              dispatch(setTab('Profile')); // Set the tab to Edit Profile
            }}
            className={navItemClasses}
          >
            <FontAwesomeIcon className="text-white" icon={solid('id-card')} />
            <span className="text-white text-xs">Profile</span>
          </Link>
          {user ? (
            <button onClick={handleSignOut} className={navItemClasses}>
              <FontAwesomeIcon
                className="text-white"
                icon={solid('sign-out')}
              />
              <span className="text-white text-xs">Sign Out</span>
            </button>
          ) : (
            <Link to={'/signin'} className={navItemClasses}>
              <FontAwesomeIcon className="text-white" icon={solid('sign-in')} />
              <span className="text-white text-xs">Sign In</span>
            </Link>
          )}
        </div>
      </nav>

      <hr></hr>
      {/* The mobile menu items (modal) */}
      <NavBarModal
        isOpen={isOpen}
        handleToggle={handleToggle}
        handleSignOut={handleSignOut}
        user={user}
        navItemClasses={navItemClasses}
      />
    </header>
  );
}
