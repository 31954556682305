// selectedCountryXSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const selectedCountryXSlice = createSlice({
  name: 'selectedCountryX',
  initialState: {
    value: 'england',
  },
  reducers: {
    setSelectedCountryX: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setSelectedCountryX } = selectedCountryXSlice.actions;
export default selectedCountryXSlice.reducer;
