import React from 'react';

function TrustedBy() {
  return (
    <>
      <div className="bg-white py-2 sm:py-6">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <h2 className="text-lg font-semibold leading-8 text-center text-indigo-600">
              Trusted by members of the UK and Ireland's top golf clubs,
              including...
            </h2>
            <div className="mx-auto my-3 grid grid-cols-2 items-start gap-x-8 gap-y-10 sm:grid-cols-5 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
              <img
                className="h-24 w-full object-contain object-center filter grayscale"
                src="https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/club-logos/hollinwell2.jpg?t=2023-10-10T09%3A16%3A47.406Z"
                alt="Hollinwell Golf Club Logo"
                loading="lazy"
                height={96}
                width={48}
              />
              <img
                className="h-24 w-full object-contain object-center filter grayscale"
                src="https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/club-logos/Littlestone.png?t=2023-10-10T09%3A07%3A40.749Z"
                alt="Littlestone Golf Club Logo"
                loading="lazy"
                height={96}
                width={48}
              />
              <img
                className="h-24 w-full object-contain object-center filter grayscale"
                src="https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/club-logos/TrumpTurnberry.PNG?t=2023-10-10T09%3A06%3A52.692Z"
                alt="Trump Turnberry Golf Club Logo"
                loading="lazy"
                height={96}
                width={48}
              />
              <img
                className="h-24 w-full object-contain object-center filter grayscale"
                src="https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/club-logos/silloth.jpg?t=2023-10-10T09%3A07%3A24.969Z"
                alt="Silloth on Solway Golf Club Logo"
                loading="lazy"
                height={96}
                width={48}
              />
              <img
                className="h-24 w-full object-contain object-center filter grayscale hidden sm:block"
                src="https://stjbqlfgvlefqghlyumg.supabase.co/storage/v1/object/public/club-logos/sheringham.png?t=2023-10-10T09%3A07%3A16.621Z"
                alt="Sheringham Golf Club Logo"
                loading="lazy"
                height={96}
                width={48}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrustedBy;
